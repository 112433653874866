@import '@/_styles/import';
$name-bg: $black-violet;
$name-color: #71d6e0;
$flyout-icon-color: #c00;
$clear-button-bg: #c6ecf3;
$trash-icon-border-color: $gray-510;
$trash-icon-bg: $white;

.promo {
  display: flex;

  margin-right: 8px;
  padding: 4px 8px;

  font-size: rem(11);
  line-height: 20px;
  color: $name-color;
  letter-spacing: 0;

  background-color: $name-bg;
  border-radius: 3px;

  @include media-breakpoint-up(lg) {
    font-size: rem(13);
  }
}

.promoIcon {
  margin-right: 5px;
}

.promoText {
  display: flex;
  gap: 10px;
  font-weight: 500;
}

.title {
  font-weight: bold;
}

.separator {
  margin: 0 10px;
  font-weight: 400;
}

.description {
  font-weight: 400;
}

.icon {
  margin-left: 8px;

  &:global(.Icon) {
    width: 20px;
    height: 20px;
    color: $vv-red;

    svg {
      display: block;
    }
  }
}
