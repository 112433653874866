@import '@/_styles/import';
.ImageContainer {
  &.-fixedRatio {
    .ImageContainer__inner {
      position: relative;
      overflow: hidden;
      height: 0;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      padding: 5px;
    }
  }

  &__imgDiv {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: $gray-010;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.Image {
  &.-fluid {
    width: 100%;
  }
}
